/**
 * Create an object with the key-value pairs from an array of pairs (key-value) and filter out the falsy values
 * @param pairs  - Array of key-value pairs
 * @returns Object with the key-value pairs
 */
export const createFilteredObject = (pairs: [string, unknown][]): Record<string, unknown> => {
    return pairs.reduce<Record<string, unknown>>((acc, [key, value]) => {
        if (value) {
            acc[key] = value;
        }
        return acc;
    }, {});
};
