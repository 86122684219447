import { z } from 'zod';

/**
 * List of meta keys that are used in the meta data array.
 * Add more meta keys here if needed.
 */
const metaKeyList = [
    'registration_form_street',
    'registration_form_zipcode',
    'registration_form_city',
    'registration_form_country',
    '_user_pob',
    // Add more meta keys here
] as const;

const metaDataSchema = z.object({
    metaKey: z.string(),
    metaValue: z.any(),
    label: z.string(),
});

export type MetaData = z.infer<typeof metaDataSchema>;
export type MetaKey = (typeof metaKeyList)[number];

/**
 * Get the meta value for a given key from the meta data array.
 * If you specify T, the function will return the value as that type. Otherwise, it will return any.
 * @param key - The meta key. (Hint: Should be something in the metaKeyList)
 * @param metaData - The meta data array.
 * @returns The meta value if found, otherwise null.
 * @throws {Error} If the key is not present in the metaKeyList.
 *
 * @example
 * // Example usage:
 * const metaData = [
 *   { metaKey: 'registration_form_street', metaValue: '123 Main St', label: 'Street' },
 *   { metaKey: 'registration_form_zipcode', metaValue: '12345', label: 'Zip Code' },
 * ];
 * const value = getMetaValue('registration_form_street', metaData);
 * console.log(value); // Output: '123 Main St'
 */
export function getMetaValue<K extends MetaKey, T = any>(
    key: K,
    metaData: MetaData[] | undefined,
): T | null {
    if (!metaKeyList.includes(key)) {
        console.warn(`"${key}" is not present in the metaKeyList. Add it to metaKeyList.`);
    }

    const meta = metaData?.find((item) => item.metaKey === key);
    return (meta?.metaValue as T) ?? null;
}

/**
 * Get the meta values for the given keys from the meta data array.
 * If you specify T, the function will return the values as that type. Otherwise, it will return any.
 * @note The type you specify for T should be the same for all keys. If you want different types for different keys, use getMetaValue instead.
 * @param keys - The meta keys. (Hint: Should be something in the metaKeyList)
 * @param metaData - The meta data array.
 * @returns An object with the meta values for the given keys.
 * @throws {Error} If any of the keys are not present in the metaKeyList.
 *
 * @example
 * // Example usage:
 * const metaData = [
 *   { metaKey: 'registration_form_street', metaValue: '123 Main St', label: 'Street' },
 *   { metaKey: 'registration_form_zipcode', metaValue: '12345', label: 'Zip Code' },
 *   { metaKey: 'registration_form_city', metaValue: 'New York', label: 'City' },
 *   { metaKey: 'registration_form_country', metaValue: 'USA', label: 'Country' },
 * ];
 * const values = getMetaValuesMulti(['registration_form_street', 'registration_form_zipcode'], metaData);
 * console.log(values); // Output: { registration_form_street: '123 Main St', registration_form_zipcode: '12345' }
 */

export function getMetaValuesMulti<K extends MetaKey, T = any>(
    keys: K[],
    metaData: MetaData[] | undefined,
): Record<K, T | null> {
    const values = {} as Record<K, T | null>;

    for (const key of keys) {
        if (!metaKeyList.includes(key)) {
            console.warn(`"${key}" is not present in the metaKeyList. Add it to metaKeyList.`);
            values[key] = null;
        } else {
            const meta = metaData?.find((item) => item.metaKey === key);
            values[key] = meta?.metaValue ?? null;
        }
    }

    return values;
}
