'use client';

import { useEffect, useState } from 'react';
import { createBreakpoint } from 'react-use';

const screens = {
    phone: 0,
    tablet: 599,
    laptop: 959,
    desktop: 1279,
};

export type Breakpoint = keyof typeof breakpoints;
export const breakpoints = {
    phone: 0,
    tablet: screens.tablet,
    laptop: screens.laptop,
    desktop: screens.desktop,
};

export const useBreakpoint = createBreakpoint(breakpoints) as () => Breakpoint;

// Todo: This is only a temporary solution. We need to find a better way to determine if the user is on a mobile device.
// This Fix: Hydration issue with useBreakpoint hook
export const useIsMobileBreakpoint = () => {
    const [mobile, setMobile] = useState(false);
    const breakpoint = useBreakpoint();

    useEffect(() => {
        setMobile(breakpoint === 'tablet' || breakpoint === 'phone');
    }, [breakpoint]);

    return mobile;
};
