'use client';

import { useEffect, useRef } from 'react';

function useExecuteOnceOnValue<T>(value: T | undefined, callback: (value: T) => void) {
    const hasRunRef = useRef(false);

    useEffect(() => {
        if (!hasRunRef.current && value !== undefined) {
            callback(value);
            hasRunRef.current = true;
        }
    }, [value]);
}

function useExecuteOnValue<T>(value: T | undefined, callback: (value: T) => void) {
    useEffect(() => {
        if (value !== undefined) {
            callback(value);
        }
    }, [value]);
}

export { useExecuteOnceOnValue, useExecuteOnValue };
