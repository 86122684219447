import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgExpandMoreIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 24 24"
            width="1em"
            fill="currentColor"
            ref={ref}
            {...props}
        >
            <path d="M24 24H0V0h24v24z" fill="none" opacity={0.87} />
            <path d="M15.88 9.29 12 13.17 8.12 9.29a.996.996 0 1 0-1.41 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59a.996.996 0 0 0 0-1.41c-.39-.38-1.03-.39-1.42 0z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgExpandMoreIcon);
export default ForwardRef;
