export function isNonNullable<T>(val: T): val is NonNullable<T> {
    return val !== null && typeof val !== 'undefined';
}

export function objectHasNullProp(obj: { [key: string]: string | object | number }) {
    for (const key of Object.keys(obj)) {
        if (!obj[key]) {
            return true;
        }
    }

    return false;
}

/**
 * Used to ensure that the passed argument cannot be null or undefined
 *
 * @param {function(): void} argument
 * @param {string} message - error message
 * @return {function(): void} - the passed argument
 */

export function ensure<T>(
    argument: T | undefined | null,
    message = 'This value was promised to be there.',
): T {
    if (argument === undefined || argument === null) {
        throw new TypeError(message);
    }

    return argument;
}

// Create type guard to check if the value is an object.
export function isObject(value: unknown): value is object {
    return typeof value === 'object' && value !== null;
}

// Create type guard to check if the value is a string.
export function isStringArray(value: unknown): value is string[] {
    return Array.isArray(value) && value.every((item) => typeof item === 'string');
}

// Create type guard to check if message is in the response data.
export const hasMessage = (responseData: unknown): responseData is { message: string } => {
    return responseData !== null && typeof responseData === 'object' && 'message' in responseData;
};

// Create type guard to check if data is in the response data.
export const hasData = (responseData: unknown): responseData is { data: unknown } => {
    return responseData !== null && typeof responseData === 'object' && 'data' in responseData;
};

// Create type guard to check if error is in the response data.
export const hasError = (responseData: unknown): responseData is { error: string } => {
    return responseData !== null && typeof responseData === 'object' && 'error' in responseData;
};

// Todo: We should update the EventDate type from the types folder where startDateTime can be null
interface EventDate {
    id: number;
    name: string;
    startDateTime: string | null;
    endDateTime: string | null;
    isShowday: boolean;
    selected: boolean;
    disabled: boolean;
    status: number;
}

// Define a type guard function to omit the startDateTime property if it's null
// Todo: We should update the EventDate type from the types folder where startDateTime can be null
export function isValidEventDate(
    event: EventDate,
): event is Omit<EventDate, 'startDateTime'> & { startDateTime: string } {
    return event.startDateTime !== null;
}

/**
 *
 * @param obj - The object to check if it's a Record<string, string>
 * @returns - A boolean indicating if the object is a Record<string, string>
 */
export function isRecordStringString(obj: unknown): obj is Record<string, string> {
    return typeof obj === 'object' && obj !== null;
}
