'use client';

import { type Dispatch, type SetStateAction, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';

interface useDebouncedSearchReturn {
    searchValue: string;
    setSearchValue: Dispatch<SetStateAction<string>>;
    searchParams: Record<string, string>;
}

const useDebouncedSearch = (
    key: string,
    startSearchLength = 2,
    debouncedTime = 300,
): useDebouncedSearchReturn => {
    const [searchValue, setSearchValue] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');

    useDebounce(
        () => {
            setDebouncedValue(searchValue);
        },
        debouncedTime,
        [searchValue],
    );

    // Search params for the attendees
    const searchParams = useMemo(() => {
        if (debouncedValue.trim().length >= startSearchLength) {
            return { [key]: debouncedValue };
        }
        return {};
    }, [debouncedValue, key, startSearchLength]);

    return { searchValue, setSearchValue, searchParams };
};

export { useDebouncedSearch };
