/**
 * Appends the X-Access-Token header to the request
 * @param token - The token to append to the headers
 * @returns - The headers with the X-Access-Token appended
 */
export const appendXAccessToken = (token: string) => {
    return {
        'X-Access-Token': token,
    };
};

/**
 *
 * @param version - The version number to append to the headers
 * @returns  - The headers with the version number appended
 */
export const appendVersionHeader = (version: number) => {
    return {
        'X-API-VERSION': `v${String(version)}`,
    };
};
