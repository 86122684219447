import { isRecordStringString } from './guard';

// Type guard to check if the input is a URLSearchParams instance
const isURLSearchParams = (input: any): input is URLSearchParams => {
    return input instanceof URLSearchParams;
};

// URLSearchParams is an iterable,
// But it needs to be converted to an array of key-value pairs before using Object.entries.
const cleanQueryParams = (queryParams: Record<string, unknown> | URLSearchParams) => {
    const entries = isURLSearchParams(queryParams)
        ? Array.from(queryParams.entries())
        : Object.entries(queryParams);

    return Object.fromEntries(entries.filter(([, value]) => value !== undefined && value !== ''));
};

/**
 *
 * @param url - The url to append the query params to
 * @param queryParams - The query params to append to the url
 * @returns - The url with the query params appended
 */
const appendQueryParams = (url: string, queryParams: unknown): string => {
    const queryString = new URLSearchParams(
        isRecordStringString(queryParams) ? queryParams : {},
    ).toString();

    // If there are query params, append them to the url
    if (isRecordStringString(queryParams) && Object.keys(queryParams).length > 0) {
        return `${url}?${queryString}`;
    }
    // If there are no query params, return the url as is
    return url;
};

export { cleanQueryParams, appendQueryParams };
