'use client';

import type {
    ColumnDef,
    FilterFnOption,
    RowSelectionOptions,
    SortingOptions,
    TableState,
    VisibilityOptions,
    ExpandedOptions,
    OnChangeFn,
    ColumnFiltersState,
} from '@tanstack/react-table';
import {
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import { TableBody, TableCell, TableHead, TableHeader, TableRoot, TableRow } from './components';
import { Loading } from '../loading';
import { cn } from '../../utils';
import { Skeleton } from '../skeleton';

interface FilterFnOptionWrapped<T> {
    filterOptions?: FilterFnOption<T>;
}

interface ColumnMeta {
    headerClassName?: string;
    cellClassName?: string;
}

interface TableProps<T extends object>
    extends RowSelectionOptions<T>,
        SortingOptions<T>,
        FilterFnOptionWrapped<T>,
        ExpandedOptions<T>,
        VisibilityOptions {
    // TODO: Remove any from ColumnDef once issue is resolved: https://github.com/TanStack/table/issues/4382
    onColumnFiltersChange?: OnChangeFn<ColumnFiltersState>;
    columns: ColumnDef<T>[];
    data: T[];
    className?: string;
    containerClassName?: string;
    isLoading?: boolean;
    state?: Partial<TableState>;
    getSubRows?: (originalRow: T, index: number) => undefined | T[];
}

/* -------------------------------------------------------------------------------------------------
 * Table
 * -----------------------------------------------------------------------------------------------*/

function Table<T extends object>({
    columns,
    data,
    className,
    containerClassName,
    isLoading,
    ...props
}: TableProps<T>) {
    const columnData = useMemo(() => columns, [columns]);
    const rowData = useMemo(() => data, [data]);

    const table = useReactTable<T>({
        data: rowData,
        columns: columnData,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        ...props,
    });

    if (isLoading) {
        return (
            <TableRoot className={cn('max-md:[&>thead]:hidden', className)}>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableHead
                                        key={header.id}
                                        className={
                                            (header.column.columnDef.meta as ColumnMeta)
                                                ?.headerClassName
                                        }
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef.header,
                                                  header.getContext(),
                                              )}
                                    </TableHead>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {Array.from({ length: 5 }).map((_, index) => (
                        <TableRow key={`loader-${index}`}>
                            {table.getHeaderGroups()[0]?.headers.map((header) => (
                                <TableCell key={`loader-cell-${header.id}`}>
                                    <Skeleton className="h-3 w-2/3 md:h-4 md:w-full" />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </TableRoot>
        );
    }

    return (
        <div className={cn('overflow-auto', containerClassName)}>
            <TableRoot className={cn('max-md:[&>thead]:hidden', className)}>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableHead
                                        key={header.id}
                                        className={
                                            (header.column.columnDef.meta as ColumnMeta)
                                                ?.headerClassName
                                        }
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef.header,
                                                  header.getContext(),
                                              )}
                                    </TableHead>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => (
                            <TableRow
                                key={row.id}
                                data-state={row.getIsSelected() && 'selected'}
                                className="group hover:bg-neutral-2"
                            >
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <TableCell
                                            key={cell.id}
                                            className={
                                                (cell.column.columnDef.meta as ColumnMeta)
                                                    ?.cellClassName
                                            }
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell
                                colSpan={table.getVisibleFlatColumns().length}
                                className="h-[49px] text-left max-md:px-4"
                            />
                        </TableRow>
                    )}
                </TableBody>
            </TableRoot>
        </div>
    );
}

Table.displayName = 'Table';

export { Table, TableRoot, TableHeader, TableBody, TableRow, TableHead, TableCell };
