import { parseISO, Locale } from 'date-fns';
import { formatInTimeZone, fromZonedTime, toZonedTime } from 'date-fns-tz';

// Get the formatted date and time in the current timezone
export const getFormattedDateTimeInCurrentTimeZone = ({
    datetime,
    formats,
    locale,
}: {
    datetime: string;
    formats?: { date?: string; time?: string };
    locale?: Locale;
}) => {
    const date = parseISO(datetime);

    // Get the current timezone from the browser
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Timezone conversion
    // Convert the date to the UTC timezone
    const utcDate = fromZonedTime(date, 'UTC');
    // Convert the date to the timezone (current timezone)
    const toCurrentTimeZone = toZonedTime(utcDate, timezone);

    // Format the date and time
    const dateWithTimezone = formatInTimeZone(
        toCurrentTimeZone,
        timezone,
        formats?.date ?? 'EEEE MMMM d',
        { locale },
    );
    const timeWithTimezone = formatInTimeZone(
        toCurrentTimeZone,
        timezone,
        formats?.time ?? 'HH:mm',
        { locale },
    );

    // Return the formatted date and time
    return {
        date: dateWithTimezone,
        time: timeWithTimezone,
    };
};
