'use client';

import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

function useWindowSize(delay = 100) {
    const [size, setSize] = useState([0, 0]);

    useEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', debounce(updateSize, delay), false);

        updateSize();

        const cleanUpFunction = () => window.removeEventListener('resize', updateSize);

        return cleanUpFunction;
    }, [delay]);

    return size;
}

export { useWindowSize };
