'use client';

import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

export const useDebouncedValue = (value: string, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const debouncedHandler = debounce((newValue: string) => {
            setDebouncedValue(newValue);
        }, delay);

        debouncedHandler(value);

        return () => {
            debouncedHandler.cancel();
        };
    }, [value, delay]);

    return debouncedValue;
};
